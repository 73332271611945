<template>
    <div id="blockmultiple" class="py-4 center-container" style="min-height:85vh" data-selenium-name="isTeleselling" :data-selenium-value="Global.isTeleselling ? 'Yes' : 'No'">
        <div class="container">
            <div class="row mb-5">
                <div class="col-12 text-center">
                    <div class="text-center mb-3">
                      {{$t("quotationNo")}} {{Global.quote.opportunityNumber}}
                    </div>
                    <h3 class="text-center">{{$t("sorryTitle")}}</h3>
                    <div class="mb-4">
                      <i18n-t tag="span" keypath="sorryMessage">{{Global.quote.productType}}</i18n-t>
                    </div>
                    <div class="text-center">
                        <img class="lazy" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/rj-thank-you.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "blockmultiple",
  mixins: [Mixin],
  data() {
    return {
      Global
    };
  },
  created() {
    this.loading("hide");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    go2pd2: function() {
      var vm = this;

      vm.loading("show", 2, function() {
        vm.$router.push(Global.productConfig.route.policydetails2);
      });
    }
  }
};
</script>
<i18n>
{
  "en": {
    "sorryTitle": "We're sorry",
    "sorryMessage": "We're sorry but there is already a policy for ({0}) under your name in our system. In accordance with Article 252 of the Indonesian Commercial Code, having a second insurance with the Insurer on the same coverage will cause the second insurance to be void; therefore our internal policy prohibits the purchase of such second insurance. If you think this is an error, please contact us at 021 5089 0822",
  },
  "id": {
    "sorryTitle": "We're sorry",
    "sorryMessage": "We're sorry but there is already a policy for ({0}) under your name in our system. In accordance with Article 252 of the Indonesian Commercial Code, having a second insurance with the Insurer on the same coverage will cause the second insurance to be void; therefore our internal policy prohibits the purchase of such second insurance. If you think this is an error, please contact us at 021 5089 0822",
  },
}
</i18n>